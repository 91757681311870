import React from "react";

import { FeatureGalleryItem } from "../../../components/_V2/FeaturesGallery";
import { StaticImage } from "gatsby-plugin-image";
import { externalLinks, pagesLinks } from "../../../pages-data/_V2/common/links";

// @ts-ignore
import ChevronRight from "../../../assets/svg/chevronRight.svg";
import AdaptiveLink from "../../../components/AdaptiveLink";

import mediaContent from "./mediaContent.module.scss";
import MobileAppBackdropIcon from "../../../assets/images/mobileFeatures/MobileAppBackdropIcon";
import backdropIconsStyles from "./backdropIconsStyles.module.scss";
import WebsiteBackdropIcon from "../../../assets/images/mobileFeatures/WebsiteBackdropIcon";
import PassKitBackdropIcon from "../../../assets/images/mobileFeatures/PassKitBackdropIcon";
import classNames from "classnames";
import { Locales } from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === "ru-KZ") {
		return {
			features: [
				{
					desktopContentWidth: 660,
					tabName: "Приложение",
					desktopTitle: "Принимай заказы \nчерез своё приложение",
					tabletTitle: "Принимай заказы \nчерез своё приложение",
					mobileTitle: "Принимай \nзаказы \nчерез своё \nприложение",
					desktopContent:
						"Запусти брендированное приложение — это быстро, без найма разработчиков. Гости смогут пользоваться бонусной программой, делать заказы на доставку.",
					tabletContent: (
						<>
							Запусти брендированное приложение&nbsp;&mdash; это быстро, бесплатно, без найма разработчиков. Гости
							смогут пользоваться&nbsp;бонусной программой, делать заказы на&nbsp;доставку.
						</>
					),
					mobileContent:
						"Запусти брендированное приложение — это быстро, без найма разработчиков. Гости смогут пользоваться бонусной программой, делать заказы на доставку.",
					detailLink: pagesLinks.indexWL,
					mediaContent: (
						<div className={mediaContent.image__container1}>
							<StaticImage
								className={mediaContent.image1}
								src="../../../assets/images/mobileFeatures/phone_kz.png"
								alt={"Мобильное приложение для ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={100}
							/>
						</div>
					),
					backdropContent: (
						<MobileAppBackdropIcon
							className={backdropIconsStyles.mobileAppBackdropIcon}
						/>
					),
				},
				{
					desktopContentWidth: 600,
					tabName: "Сайт",
					desktopTitle: "Запусти собственный сайт \nс доставкой",
					tabletTitle: "Запусти собственный\nсайт с доставкой",
					mobileTitle: "Запусти \nсобственный\nсайт \nс доставкой",
					desktopContent:
						"Получай больше заказов с помощью сайта своего заведения. Подключение доставки с расчетом стоимости по зонам, опция самовывоза, всегда актуальное меню и различные способы оплаты.",
					tabletContent: (
						<>
							Получай больше заказов с&nbsp;помощью сайта своего заведения. Подключение доставки с&nbsp;расчетом
							стоимости по зонам,&nbsp;опция самовывоза, всегда актуальное меню и&nbsp;различные способы оплаты.
						</>
					),
					mobileContent: (
						<>
							Получай больше заказов с&nbsp;помощью сайта своего заведения. Подключение доставки с&nbsp;расчетом
							стоимости по&nbsp;зонам, опция самовывоза, всегда актуальное меню и различные&nbsp;способы оплаты.
						</>
					),
					detailLink: pagesLinks.indexWL,
					mediaContent: (
						<div className={mediaContent.image__container2}>
							<StaticImage
								className={mediaContent.image2}
								src="../../../assets/images/mobileFeatures/shop-feature_kz.webp"
								alt={"Сайт для ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								width={2322}
								height={1666}
								quality={100}
								outputPixelDensities={[1, 2]}
							/>
						</div>
					),
					backdropContent: (
						<WebsiteBackdropIcon
							className={backdropIconsStyles.websiteBackdropIcon}
						/>
					),
				},
				{
					desktopContentWidth: 530,
					tabName: "Карты лояльности",
					desktopTitle: "Карты виртуальные,\nа гости реальные",
					mobileTitle: "Карты \nвиртуальные, \nа гости \nреальные",
					desktopContent:
						"Выпускай бонусные карты для гостей, храни и актуализируй данные в своей CRM-системе. Карты лояльности универсальны, поэтому работают на Android и iOS устройствах.",
					detailLink: pagesLinks.passkit,
					mediaContent: (
						<div className={mediaContent.image__container3}>
							<StaticImage
								className={mediaContent.image3}
								src="../../../assets/images/mobileFeatures/card_kz.png"
								alt={"Карты лояльности для ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								width={390}
								quality={100}
							/>

							<div className={mediaContent.content__container_kz}>
								<StaticImage
									className={mediaContent.code_kz}
									src="../../../assets/images/mobileFeatures/walletQrCode_kz.png"
									alt={"qr code"}
									objectFit={"contain"}
									placeholder={"blurred"}
									width={120}
									quality={90}
								/>

								<AdaptiveLink
									className={mediaContent.walletLink_kz}
									href={externalLinks.demoWl.href}
									useGatsbyLink={externalLinks.demoWl.useGatsbyLink}
								>
								<span className={mediaContent.walletLink__text_kz}>
									{"Попробуй прямо сейчас"}
								</span>

									<span
										className={classNames(
											mediaContent.walletLink__text,
											mediaContent.walletLink__textMobile,
										)}
									>
										{"Попробовать"}
									</span>
								</AdaptiveLink>
							</div>
						</div>
					),
					backdropContent: (
						<PassKitBackdropIcon
							className={backdropIconsStyles.passKitBackdropIcon}
						/>
					),
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		};
	}

	if (locale === "kz-KZ") {
		return {
			features: [
				{
					desktopContentWidth: 660,
					tabName: "Қосымша",
					desktopTitle: "Тапсырыстарды өз қосымшаңыз арқылы қабылдаңыз",
					desktopContent:
						"Брендтелген қосымшаны іске қосыңыз — бұл тез, әзірлеушілерді жалдаусыз болады. Қонақтар бонустық бағдарламаны пайдаланып, жеткізуге тапсырыстарды жасай алады.",
					detailLink: pagesLinks.indexWL,
					mediaContent: (
						<div className={mediaContent.image__container1}>
							<StaticImage
								className={mediaContent.image1}
								src="../../../assets/images/mobileFeatures/phone_kz.png"
								alt={"Мобильное приложение для ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={100}
							/>
						</div>
					),
					backdropContent: (
						<MobileAppBackdropIcon
							className={backdropIconsStyles.mobileAppBackdropIcon}
						/>
					),
				},
				{
					desktopContentWidth: 600,
					tabName: "Сайт",
					desktopTitle: "Жеткізу мүмкіндігі бар сайтыңызды іске қосыңыз",
					desktopContent:
						"Мекемеңіздің сайты көмегімен көбірек тапсырыстар алыңыз. Аймақтар бойынша құнды есептеумен жеткізуді қосу, өзімен әкету опциясы, әрқашан өзекті мәзір және әртүрлі төлеу тәсілдері.",
					detailLink: pagesLinks.indexWL,
					mediaContent: (
						<div className={mediaContent.image__container2}>
							<StaticImage
								className={mediaContent.image2}
								src="../../../assets/images/mobileFeatures/shop-feature_kz.webp"
								alt={"Сайт для ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								width={2322}
								height={1666}
								quality={100}
								outputPixelDensities={[1, 2]}
							/>
						</div>
					),
					backdropContent: (
						<WebsiteBackdropIcon
							className={backdropIconsStyles.websiteBackdropIcon}
						/>
					),
				},
				{
					desktopContentWidth: 530,
					tabName: "Адалдық карталары",
					desktopTitle: "Карталар виртуалды, ал қонақтар шынайы",
					desktopContent:
						"Қонақтар үшін бонустық карталарды шығарыңыз, деректерді өзіңіздің CRM-жүйеңізде сақтап, өзектендіріңіз. Адалдық карталары әмбебап, сондықтан Android және iOS құрылғыларында жұмыс істейді.",
					detailLink: pagesLinks.passkit,
					mediaContent: (
						<div className={mediaContent.image__container3}>
							<StaticImage
								className={mediaContent.image3}
								src="../../../assets/images/mobileFeatures/card_kz.png"
								alt={"Карты лояльности для ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								width={390}
								quality={100}
							/>

							<div className={mediaContent.content__container_kz}>
								<StaticImage
									className={mediaContent.code_kz}
									src="../../../assets/images/mobileFeatures/walletQrCode_kz.png"
									alt={"qr code"}
									objectFit={"contain"}
									placeholder={"blurred"}
									width={120}
									quality={90}
								/>

								<AdaptiveLink
									className={mediaContent.walletLink_kz}
									href={externalLinks.demoWl.href}
									useGatsbyLink={externalLinks.demoWl.useGatsbyLink}
								>
								<span className={mediaContent.walletLink__text_kz}>
									{"Дәл қазір көріңіз"}
								</span>

									<span
										className={classNames(
											mediaContent.walletLink__text,
											mediaContent.walletLink__textMobile,
										)}
									>
										{"Попробовать"}
									</span>
								</AdaptiveLink>
							</div>
						</div>
					),
					backdropContent: (
						<PassKitBackdropIcon
							className={backdropIconsStyles.passKitBackdropIcon}
						/>
					),
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		};
	}

	if (locale === "ru-BY") {
		return {
			features: [
				{
					desktopContentWidth: 660,
					columnStyleDesktop: mediaContent.section_by__desktop,
					columnStyleTablet: "",
					columnStyleMobile: "",
					tabName: "Приложение",
					desktopTitle: "Зарабатывай \nбез посредников",
					tabletTitle: "Зарабатывай без посредников",
					mobileTitle: "Зарабатывай \nбез \nпосредников",
					desktopContent: (
						<>
							Запусти брендированное приложение&nbsp;&mdash; это быстро, без найма разработчиков. Гости смогут
							пользоваться бонусной программой, делать заказы на&nbsp;доставку.
						</>
					),
					tabletContent: (
						<>
							Запусти брендированное приложение&nbsp;&mdash; это быстро, без найма разработчиков. Гости смогут
							пользоваться бонусной программой, делать заказы на&nbsp;доставку.
						</>
					),
					mobileContent: (
						<>
							Запусти брендированное <br />
							приложение&nbsp;&mdash; это быстро, <br />
							без найма разработчиков. <br />
							Гости смогут пользоваться <br />
							бонусной программой, делать <br />
							заказы на&nbsp;доставку.
						</>
					),
					detailLink: pagesLinks.indexWL,
					mediaContent: (
						<div className={mediaContent.image__container1}>
							<StaticImage
								className={classNames(mediaContent.image1, mediaContent.image1__marketing)}
								src="../../../assets/images/mobileFeatures/phone_by.png"
								alt={"Мобильное приложение для ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								breakpoints={[320, 370]}
								sizes="(max-width: 1319px) 320px, (min-width: 1320px) 370px"
								quality={50}
							/>
						</div>
					),
					backdropContent: (
						<MobileAppBackdropIcon
							className={backdropIconsStyles.mobileAppBackdropIcon}
						/>
					),
				},
				{
					desktopContentWidth: 600,
					tabName: "Сайт",
					desktopTitle: "Лёгкий способ заказать \nдоставку через сайт",
					tabletTitle: "Лёгкий способ заказать доставку \nчерез сайт",
					mobileTitle: "Лёгкий способ \nзаказать \nдоставку \nчерез сайт",
					desktopContent: (
						<>
							Получай больше заказов с&nbsp;помощью сайта своего заведения. Подключение доставки с&nbsp;расчетом
							стоимости по&nbsp;зонам, опция самовывоза, всегда актуальное меню и&nbsp;различные способы оплаты.
						</>
					),
					tabletContent: (
						<>
							Получай больше заказов с&nbsp;помощью сайта своего заведения. Подключение доставки с&nbsp;расчетом
							стоимости по зонам,&nbsp;опция самовывоза, всегда актуальное меню и&nbsp;различные способы оплаты.
						</>
					),
					mobileContent: (
						<>
							Запусти брендированное приложение&nbsp;&mdash; быстро, бесплатно, без найма разработчиков. Гости смогут
							пользоваться бонусной программой, делать заказы на доставку.&nbsp;Оставаться с&nbsp;ними на
							связи&nbsp;просто&nbsp;&mdash; об&nbsp;акциях и&nbsp;предложениях можно оповестить через push.
						</>
					),
					detailLink: pagesLinks.indexWL,
					mediaContent: (
						<div className={mediaContent.image__container2}>
							<StaticImage
								className={mediaContent.image2}
								src="../../../assets/images/mobileFeatures/shop-feature_by.png"
								alt={"Сайт для ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								width={2322}
								height={1666}
								quality={100}
								outputPixelDensities={[1, 2]}
							/>
						</div>
					),
					backdropContent: (
						<WebsiteBackdropIcon
							className={backdropIconsStyles.websiteBackdropIcon}
						/>
					),
				},
				{
					desktopContentWidth: 530,
					tabName: "Карты лояльности",
					desktopTitle: "Карты виртуальные, \nа гости реальные",
					tabletTitle: "Карты виртуальные, а гости реальные",
					mobileTitle: "Карты \nвиртуальные, \nа гости \nреальные",
					desktopContent:
						"Выпускай бонусные карты для гостей, храни и актуализируй данные в своей CRM-системе. Карты лояльности универсальны, поэтому работают на Android и iOS устройствах.",
					detailLink: pagesLinks.passkit,
					mediaContent: (
						<div className={mediaContent.image__container3}>
							<StaticImage
								className={mediaContent.image3}
								src="../../../assets/images/mobileFeatures/card_by.png"
								alt={"Карты лояльности для ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								width={390}
								quality={100}
							/>

							<div className={mediaContent.content__container}>
								<StaticImage
									className={mediaContent.code}
									src="../../../assets/images/mobileFeatures/walletQrCode_by.png"
									alt={"qr code"}
									objectFit={"cover"}
									placeholder={"blurred"}
									width={120}
									quality={90}
								/>

								<AdaptiveLink
									className={mediaContent.walletLink}
									href={externalLinks.demoWl.href}
									useGatsbyLink={externalLinks.demoWl.useGatsbyLink}
								>
							<span className={mediaContent.walletLink__text}>
								{"Попробуй!"}
							</span>

									<span
										className={classNames(
											mediaContent.walletLink__text,
											mediaContent.walletLink__textMobile,
										)}
									>
								{"Попробовать"}
							</span>
								</AdaptiveLink>
							</div>
						</div>
					),
					backdropContent: (
						<PassKitBackdropIcon
							className={backdropIconsStyles.passKitBackdropIcon}
						/>
					),
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		};
	}

	return {
		features: [
			{
				desktopContentWidth: 660,
				tabName: "Приложение",
				desktopTitle: "Работай с гостями напрямую через приложение",
				mobileTitle: "Работай с гостями напрямую через приложение",
				desktopContent:
					"Запусти брендированное приложение — это быстро, без найма разработчиков. Гости смогут пользоваться бонусной программой, делать заказы на доставку.",
				detailLink: pagesLinks.indexWL,
				mediaContent: (
					<div className={mediaContent.image__container1}>
						<StaticImage
							className={mediaContent.image1}
							src="../../../assets/images/mobileFeatures/phone.png"
							alt={"Мобильное приложение для ресторана"}
							objectFit={"contain"}
							placeholder={"blurred"}
							breakpoints={[320, 370]}
							sizes="(max-width: 1319px) 320px, (min-width: 1320px) 370px"
							quality={50}
						/>
					</div>
				),
				backdropContent: (
					<MobileAppBackdropIcon
						className={backdropIconsStyles.mobileAppBackdropIcon}
					/>
				),
			},
			{
				desktopContentWidth: 600,
				tabName: "Сайт",
				desktopTitle: "Запусти сайт с доставкой для своего заведения",
				desktopContent:
					"Получай больше заказов с помощью сайта своего заведения. Подключение доставки с расчетом стоимости по зонам, опция самовывоза, всегда актуальное меню и различные способы оплаты.",
				detailLink: pagesLinks.indexWL,
				additionalDetailLink: externalLinks.demoWlsite,
				mediaContent: (
					<div className={mediaContent.image__container2}>
						<StaticImage
							className={mediaContent.image2}
							src="../../../assets/images/mobileFeatures/shop-feature.webp"
							alt={"Сайт для ресторана"}
							objectFit={"contain"}
							placeholder={"blurred"}
							width={2322}
							height={1666}
							quality={100}
							outputPixelDensities={[1, 2]}
						/>
					</div>
				),
				backdropContent: (
					<WebsiteBackdropIcon
						className={backdropIconsStyles.websiteBackdropIcon}
					/>
				),
			},
			{
				desktopContentWidth: 530,
				tabName: "Карты лояльности",
				desktopTitle: "Теперь и электронные карты лояльности",
				desktopContent:
					"Выпускай бонусные карты для гостей, храни и актуализируй данные в своей CRM-системе. Карты лояльности универсальны, поэтому работают на Android и iOS устройствах.",
				detailLink: pagesLinks.passkit,
				mediaContent: (
					<div className={mediaContent.image__container3}>
						<StaticImage
							className={mediaContent.image3}
							src="../../../assets/images/mobileFeatures/card.png"
							alt={"Карты лояльности для ресторана"}
							objectFit={"contain"}
							placeholder={"blurred"}
							width={390}
							quality={100}
						/>

						<div className={mediaContent.content__container}>
							<StaticImage
								className={mediaContent.code}
								src="../../../assets/images/mobileFeatures/walletQrCode.png"
								alt={"qr code"}
								objectFit={"cover"}
								placeholder={"blurred"}
								width={120}
								quality={90}
							/>

							<AdaptiveLink
								className={mediaContent.walletLink}
								href={externalLinks.demoWl.href}
								useGatsbyLink={externalLinks.demoWl.useGatsbyLink}
							>
							<span className={mediaContent.walletLink__text}>
								{"Сканируй!"}
							</span>

								<span
									className={classNames(
										mediaContent.walletLink__text,
										mediaContent.walletLink__textMobile,
									)}
								>
								{"Попробовать!"}
							</span>
							</AdaptiveLink>
						</div>
					</div>
				),
				backdropContent: (
					<PassKitBackdropIcon
						className={backdropIconsStyles.passKitBackdropIcon}
					/>
				),
			},
		],
	} as {
		features: Array<FeatureGalleryItem>
	};
};
