import * as React from "react"

const PassKitBackdropIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 607 514"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M373.146 476.452C439.111 426.956 534.303 349.468 573.972 287.495C603.654 241.196 613.312 183.835 600.401 130.502C572.818 9.01422 420.072 -40.6286 324.927 40.7853C319.576 45.3339 314.643 50.3763 309.58 55.5541C306.11 59.1042 300.443 59.1039 296.972 55.5538C291.909 50.3763 286.977 45.3339 281.625 40.7853C186.481 -40.6286 33.7351 9.01422 6.15114 130.502C-6.75921 183.835 2.89743 241.196 32.5806 287.495C72.2489 349.468 167.441 426.956 233.406 476.452C241.434 482.477 255.777 492.725 269.389 502.358C289.701 516.733 316.851 516.733 337.164 502.358C350.775 492.725 365.119 482.477 373.146 476.452Z"
			fill="#E4F7F2"
		/>
	</svg>
)

export default PassKitBackdropIcon
